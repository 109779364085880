.PartnersListRow .CircleStatus {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    box-sizing: border-box;
}

.PartnersListRow .CircleStatus:hover {
    border: 2px solid #3498db;
    cursor: pointer;
}