.PaypalPayment {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(5, auto);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
        padding: 40px;
}

.PaypalPayment .Headline {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.PaypalPayment .PaypalFilter {
    grid-column: 1 / 13;
    box-sizing: border-box;
}