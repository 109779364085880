.PublisherFilter .ActionButton.ActionFilters {
    width: 115px;
}

.PDFPopUp {
    position: fixed;
    top: 59px;
    right: 0;
    bottom: 0;
    width: 50vw;
}