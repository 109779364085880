.LabelCard {
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 0px;
    padding: 5px 10px;
    text-align: center;
    color: #FFFFFF;
    width: fit-content;
    height: fit-content;
    font-size: 12px;
}

.LabelCard.Alternative {
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    margin-top: 0px;
    padding: 5px 10px;
    text-align: center;
    color: #FFFFFF;
    width: fit-content;
    font-size: 12px;
}