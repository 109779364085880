.PartnersPage {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.PartnersPage .Headline {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.PartnersPage .PartnersListCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.PartnersPage .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
