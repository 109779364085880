.PublishersListTable th {
    text-align: left;
    color: white;
}

.PublishersListTable ul.Pagination {
    display: flex;
    width: fit-content;
    list-style: none;
    justify-content: space-around;
    padding-left: 0px;
    margin: auto;
}

.PublishersListTable ul.Pagination li.selected {
    color: #70a1ff;
}

.PublishersListTable ul.Pagination li {
    padding: 5px;
}

.PublishersListTable ul.Pagination li:hover {
    cursor: pointer;
    color: #3498db;
}